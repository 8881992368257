@import url('./components/Typography/Typography.module.css');

html,
body {
  margin: 0;
}

:root {
  --color-white: #fff;
  --color-white-rgb: 255 255 255;
  --color-orange: #f96943;
  --color-primary: #440031;
  --color-primary-rgb: 249 105 67;
}

@font-face {
  font-family: Overpass Thin;
  src: url("overpass-v12-latin-100.4d1df838.woff2") format("woff2");
}

@font-face {
  font-family: Overpass Light;
  src: url("overpass-v12-latin-300.23871b27.woff2") format("woff2");
}

@font-face {
  font-family: Overpass Regular;
  src: url("overpass-v12-latin-regular.c30be674.woff2") format("woff2");
}

@font-face {
  font-family: Overpass ExtraBold;
  src: url("overpass-v12-latin-800.b8feafc3.woff2") format("woff2");
}

:root {
  letter-spacing: .1px;
  color: var(--color-white);
  font-family: Overpass Regular, sans-serif;
  font-size: 16px;
}

.ahVdMa_coverText {
  font-family: Overpass Light, sans-serif;
  font-size: 5.875rem;
}

.ahVdMa_subText {
  font-family: Overpass Thin, sans-serif;
  font-size: 2.25rem;
}

.ahVdMa_regularText {
  font-family: Overpass Regular, sans-serif;
  font-size: 1rem;
}

.ahVdMa_importantText {
  font-family: Overpass ExtraBold, sans-serif;
}

@media screen and (max-width: 700px) {
  .ahVdMa_coverText {
    font-size: 4.375rem;
  }

  .ahVdMa_subText {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 500px) {
  .ahVdMa_coverText {
    font-size: 2.8125rem;
  }

  .ahVdMa_subText {
    font-size: 1.25rem;
  }
}

html, body {
  margin: 0;
}

:root {
  --color-white: #fff;
  --color-white-rgb: 255 255 255;
  --color-orange: #f96943;
  --color-primary: #440031;
  --color-primary-rgb: 249 105 67;
}

.abX6YW_container {
  width: 100%;
  min-height: 100vh;
  background: var(--color-primary);
  flex-direction: column;
  display: flex;
}

.abX6YW_photoCitation {
  font-size: .625rem;
  font-style: inherit;
  writing-mode: vertical-rl;
  position: absolute;
  top: 4.5rem;
  right: .5rem;
  transform: rotate(180deg);
}

.abX6YW_main {
  width: 100%;
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
}

.Gp5U5W_buttonPrimary {
  height: 2.25rem;
  width: 9.375rem;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--color-white);
  background-color: var(--color-orange);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: .3125rem;
  line-height: 2.25rem;
}

.Gp5U5W_buttonPrimary:disabled {
  opacity: .5;
}

.FV8jnq_header {
  width: 100%;
  height: 3.75rem;
  box-sizing: border-box;
  background: #33002566;
  justify-content: space-between;
  align-items: center;
  padding-inline: calc(50% + min(90%, 69.375rem) / -2);
  display: flex;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 500px) {
  .FV8jnq_header {
    height: 2.5rem;
  }
}

.FV8jnq_title {
  margin: 0;
  font-family: Overpass Light, sans-serif;
}

.FV8jnq_navLine {
  letter-spacing: -.1px;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.FV8jnq_kiltLink {
  background-image: url("kilt-logo.2649dcb6.svg");
  background-size: 3rem;
}

@media screen and (max-width: 500px) {
  .FV8jnq_navLine {
    font-size: .75rem;
  }

  .FV8jnq_kiltLink {
    height: 1.25rem;
    width: 2.5rem;
    background-size: 1.75rem;
  }
}

.GnKuuq_footer {
  width: 100%;
  background: linear-gradient(180deg, var(--color-primary) 0%, #000 100%);
  box-sizing: border-box;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-end;
  padding-block: 1.25rem 3.625rem;
  padding-inline: calc(50% + min(90%, 69.375rem) / -2);
  display: flex;
}

@media screen and (max-width: 500px) {
  .GnKuuq_footer {
    flex-direction: column;
    align-items: center;
    padding-block: 2rem 1.375rem;
  }
}

.GnKuuq_heading {
  margin: 0 0 .25rem;
  font-size: 1rem;
}

.GnKuuq_navMenu {
  flex-direction: column;
  display: flex;
}

@media screen and (max-width: 500px) {
  .GnKuuq_navMenu {
    align-items: center;
  }
}

.GnKuuq_navLink {
  width: fit-content;
  color: var(--color-white);
  margin: .25rem 0;
  display: inline-block;
}

.GnKuuq_support {
  margin: 1.5rem 0 0;
}

.GnKuuq_copyright {
  align-self: flex-end;
  margin: 0;
  font-size: .625rem;
}

@media screen and (max-width: 500px) {
  .GnKuuq_copyright {
    align-self: center;
    margin-top: 1.25rem;
  }
}

.eZ2MiW_container {
  box-sizing: border-box;
  width: 90%;
  max-width: 75rem;
  background: rgb(var(--color-primary-rgb) / 10%);
  border: 1px solid rgb(var(--color-primary-rgb) / 50%);
  flex-direction: column;
  margin-top: -7.8125rem;
  padding: 2.25rem 3.125rem;
  display: flex;
}

@media screen and (max-width: 500px) {
  .eZ2MiW_container {
    padding: 1rem .75rem;
  }
}

.eZ2MiW_heading {
  margin: 0 0 .25rem;
}

.eZ2MiW_txStatus {
  background: 0 no-repeat;
  margin: 0 0 1.75rem;
  padding-left: 2.5rem;
}

@media screen and (max-width: 500px) {
  .eZ2MiW_txStatus {
    background-size: 1.5rem;
  }
}

.eZ2MiW_txPrepared {
  background-image: url("pending.391b6336.svg");
}

.eZ2MiW_txComplete {
  background-image: url("ok.6108c32e.svg");
}

.eZ2MiW_txError {
  background-image: url("error.78400c90.svg");
  margin: 0;
}

.eZ2MiW_addressContainer {
  width: 100%;
  align-items: flex-end;
  gap: .75rem;
  margin-bottom: .5rem;
  display: flex;
}

@media screen and (max-width: 500px) {
  .eZ2MiW_addressContainer {
    flex-direction: column;
    align-items: center;
  }
}

.eZ2MiW_address {
  overflow-wrap: break-word;
  max-width: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.eZ2MiW_addressName {
  padding-left: .25rem;
}

.eZ2MiW_addressValue {
  border: 1px solid var(--color-orange);
  background: #3a0029;
  padding: .25rem;
}

.eZ2MiW_web3Name {
  padding-left: .25rem;
  font-size: 2.25rem;
  line-height: 2.25rem;
}

.eZ2MiW_termsLine {
  box-sizing: border-box;
  width: calc(100% + 6.25rem);
  background: rgb(var(--color-primary-rgb) / 50%);
  align-self: center;
  align-items: center;
  margin: .5rem 0 1.5rem;
  padding: .5rem 3.125rem;
  display: flex;
}

@media screen and (max-width: 500px) {
  .eZ2MiW_termsLine {
    width: calc(100% + 1.5rem);
    padding: .5rem 1.75rem;
  }
}

.eZ2MiW_termsLineEnabled {
  background: var(--color-orange);
}

.eZ2MiW_termsLabel {
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

.eZ2MiW_termsLabelText {
  margin: 0;
}

.eZ2MiW_accept {
  display: none;
}

.eZ2MiW_checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid var(--color-white);
  background: var(--color-primary);
  border-radius: 2px;
  flex-shrink: 0;
  margin-right: .5rem;
  display: inline-block;
  position: relative;
}

.eZ2MiW_checkbox:before {
  content: " ";
  width: .75rem;
  height: .75rem;
  background: url("arrow.31776c6b.svg") center / contain no-repeat;
  position: absolute;
  top: .25rem;
  left: -1.25rem;
}

.eZ2MiW_accept:checked + .eZ2MiW_checkbox:after {
  width: 1.25rem;
  height: 1.25rem;
  content: " ";
  background: url("checkmark.f46534ba.svg") center / contain no-repeat;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.eZ2MiW_termsLink {
  color: var(--color-white);
}

.eZ2MiW_incomplete {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.eZ2MiW_instruction {
}

.eZ2MiW_cost {
  flex-direction: column;
  margin: 0;
  display: flex;
}

.eZ2MiW_costValue {
  margin-block: .25rem;
  font-size: 2.25rem;
  line-height: 2.25rem;
}

.eZ2MiW_costDetails {
  font-size: .875rem;
  font-style: italic;
}

.eZ2MiW_orderLine {
  margin-block: 0;
}

.eZ2MiW_order {
  width: auto;
  padding-inline: 1rem;
}

.eZ2MiW_paypal {
  width: 15.625rem;
  height: 2.25rem;
}

@media screen and (max-width: 500px) {
  .eZ2MiW_paypal {
    width: 100%;
    height: auto;
  }
}

.eZ2MiW_servicesHeading {
  margin-top: 2.75rem;
}

.eZ2MiW_servicesSubline {
  margin-block: .375rem;
}

.eZ2MiW_services {
  padding-left: 1.5rem;
}

.eZ2MiW_serviceLink {
  color: var(--color-white);
}

.eZ2MiW_restart {
  margin-top: 1rem;
}

@keyframes eZ2MiW_rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.eZ2MiW_dialog[open]::backdrop {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: #4400314d;
}

.eZ2MiW_dialog[open] {
  width: 100%;
  height: 100vh;
  color: var(--color-white);
  background: none;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 0;
  display: flex;
}

.eZ2MiW_dialog[open]:before {
  content: "";
  width: 3.125rem;
  height: 3.125rem;
  background: url("spinner.e5207b42.svg") center / contain no-repeat;
  animation: 1.5s linear infinite eZ2MiW_rotation;
}

.D5v2lG_avatar {
  height: 3.875rem;
  width: 3.875rem;
  background: var(--color-orange);
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.D5v2lG_pending {
  background: #a7a7a799;
}

.D5v2lG_identicon {
  --size: calc(50rem / 16);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
}

.CF8T3q_container {
  width: 100%;
  border: 1px solid var(--color-progress);
  --color-progress: #3a0029;
  padding: 0;
  list-style: none;
  display: flex;
}

.CF8T3q_step {
  border: 1px solid var(--color-progress);
  text-transform: uppercase;
  color: rgba(var(--color-white-rgb) / 50%);
  flex-grow: 1;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: .75rem;
}

.CF8T3q_current {
  color: var(--color-white);
  --color-transparent: rgba(var(--color-white-rgb) / 0%);
  background-image: repeating-linear-gradient( 120deg, var(--color-transparent), var(--color-transparent) 5px, var(--color-primary) 5px, var(--color-primary) 10px );
}

.CF8T3q_completed {
  color: var(--color-white);
  background: var(--color-progress);
}

.WjHWHG_cover {
  width: 100%;
  box-sizing: border-box;
  background: url("splash.0b56e537.jpg") 0 100% / cover no-repeat;
  flex-direction: column;
  align-items: center;
  padding-block: 9.375rem 10.9375rem;
  padding-inline: calc(50% + min(90%, 69.375rem) / -2);
  display: flex;
}

@media screen and (max-width: 500px) {
  .WjHWHG_cover {
    padding-block: 4rem 9.375rem;
  }
}

.WjHWHG_heading {
  background: url("did_logo.ad2c4007.svg") top no-repeat;
  margin: 0 0 .75rem;
  padding-top: 2rem;
  font-family: Overpass Thin, sans-serif;
}

.WjHWHG_subline {
  margin: 0;
}

.WjHWHG_infoContainer {
  margin: 9.25rem 0 0;
}

@media screen and (max-width: 500px) {
  .WjHWHG_infoContainer {
    margin: 1rem 0 0;
  }
}

.WjHWHG_infoHeading {
  margin: 0;
  font-size: 1rem;
}

.WjHWHG_infoTagline {
  margin: 0 0 1rem;
  font-family: Overpass Thin, sans-serif;
  font-size: 3rem;
}

.WjHWHG_info {
  margin-block: .75rem;
  font-size: 1.3125rem;
  line-height: 1.5rem;
}

.WjHWHG_infoPoints {
  padding-left: 2rem;
}

.WjHWHG_infoPoint {
  line-height: 1.75rem;
}

@media screen and (max-width: 700px) {
  .WjHWHG_infoTagline {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 500px) {
  .WjHWHG_infoTagline {
    font-size: 2rem;
  }

  .WjHWHG_info {
    font-size: 1.125rem;
  }
}

.wruOga_cover {
  background-image: url("splash.258463f7.jpg");
}

.wruOga_heading {
  background-image: url("w3n_logo.a90a9ee6.svg");
}

.wruOga_subline {
}

.wruOga_infoContainer {
}

.wruOga_infoHeading {
}

.wruOga_infoTagline {
}

.wruOga_info {
}

.wruOga_info a {
  color: var(--color-white);
}

/*# sourceMappingURL=index.8db545c0.css.map */
